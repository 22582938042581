import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { useContext } from 'react';
import { PortableTextContext } from '../..';
import LinkListBlockComponent from '../../../linklistblock';
const LinkListBlock = ({ value }) => {
    const backgroundColor = useContext(PortableTextContext)?.backgroundColor;
    return (_jsx(LinkListBlockComponent, { links: value.links, title: value.title, titleHtmlMarkup: value.titleHtmlMarkup, additionalLinkList: value.additionalLinkList, color: backgroundColor ? backgroundColor : value.color, useGridLayout: value.useGridLayout }));
};
export default LinkListBlock;
