/**
 * Returnerer Path fra HN.Page objektet - eller tom string hvis den ikke finnes
 */
export const getPath = () => {
    return window.HN?.Page?.Path ?? '';
};
/**
 * Returnerer Version fra HN.Page objektet
 */
export const getVersion = () => {
    return window.HN?.Page?.Version;
};
/**
 * Returnerer Assets fra HN.Page objektet
 */
export const getAssets = () => {
    return window.HN?.Page?.Assets;
};
/**
 * Returnerer Assets fra HN.Page objektet - eller tom string hvis den ikke finnes
 */
export const getAssetsUrl = () => {
    return window.HN?.Page?.Assets ?? '';
};
/**
 * Returnerer VerticalAssetsPath fra HN.Page objektet - eller tom string hvis den ikke finnes
 */
export const getVerticalAssetsPath = () => {
    return window.HN?.Page?.VerticalAssetsPath ?? '';
};
/**
 * Oppdaterer Path key på HN.Page objektet
 * @param newPath verdien som settes i Path
 */
export const setPath = (newPath) => {
    window.HN = window.HN || {};
    window.HN.Page = window.HN.Page || {};
    window.HN.Page.Path = newPath;
};
/**
 * Hent ID som skal brukes på h1-elementet på siden
 * @returns ID
 */
export const getSidetittelId = () => 'sidetittel';
