import { useRouteError } from 'react-router-dom';

import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

const ErrorElement: React.FC = () => {
  const error = useRouteError();

  if (error instanceof Error) {
    return <NotificationPanel variant={'error'}>{error.message}</NotificationPanel>;
  }
  if (typeof error === 'string') {
    return <NotificationPanel variant={'error'}>{error}</NotificationPanel>;
  }

  return <NotificationPanel variant={'error'}>{'Det har skjedd en teknisk feil. Prøv igjen senere.'}</NotificationPanel>;
};

export default ErrorElement;
