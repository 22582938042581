import { HelsenorgeHttpError, getCorrelationIdFromResponse } from './hn-proxy-service';
/**
 * Returnerer en url-friendly string fra et objekt, f.eks. ?a=b&x=y
 * @param params Objekt med parametere som skal gjøres om til en string { a : 'b', x: 'y' }
 */
export const parseParams = (params) => {
    if (params) {
        const queryString = Object.keys(params)
            .map(key => {
            if (Object.prototype.hasOwnProperty.call(params, key) && typeof params[key] !== 'undefined') {
                return `${key}=${params[key]}`;
            }
        })
            .filter(x => !!x)
            .join('&');
        if (queryString) {
            return `?${queryString}`;
        }
    }
    return '';
};
/**
 * Oppretter nytt Header-objekt med Accept og Content-type
 * @param type application/json er default
 */
export const createBaseHeaders = (type = 'application/json') => new Headers({
    Accept: type,
    'Content-Type': type,
});
/**
 * Sjekker status på en Response og returnerer en Promise avhengig av status
 * @param response Response objektet fra HTTP request
 * @throws {HelsenorgeHttpError} Dersom APIet svarer med en feil som ikke er i standard-format
 * @throws {Error} Dersom det skjer andre feil
 */
export const checkStatus = (response) => {
    const contentType = response.headers.get('content-type');
    const correlationId = getCorrelationIdFromResponse(response);
    if (contentType?.indexOf('application/json') !== -1) {
        if (response.status === 204) {
            return Promise.resolve(null);
        }
        if (response.ok) {
            return response.json();
        }
        return response
            .json()
            .then(error => {
            // Håndtering av feil der serveren har respondert med gyldig JSON
            throw new HelsenorgeHttpError(response.status, 'Det har skjedd en teknisk feil. Prøv igjen senere.', correlationId, error);
        })
            .catch(error => {
            if (error instanceof HelsenorgeHttpError) {
                throw error;
            }
            // Håndtering av feil der serveren har respondert med ugyldig JSON
            throw new HelsenorgeHttpError(response.status, 'Det har skjedd en teknisk feil. Prøv igjen senere.', correlationId);
        });
    }
    throw new Error('Det har skjedd en teknisk feil. Prøv igjen senere.');
};
