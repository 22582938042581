import { jsx as _jsx } from "react/jsx-runtime";
import Expander from '@helsenorge/designsystem-react/components/Expander';
import { PortableText } from '../../richtext';
const EkspanderendeTekstBlock = ({ title, mainContent, }) => {
    if (title.length === 0 || mainContent.length === 0) {
        return null;
    }
    return (_jsx(Expander, { title: title, noNestedLine: true, renderChildrenWhenClosed: true, children: _jsx(PortableText, { value: mainContent }) }));
};
export default EkspanderendeTekstBlock;
