
/* tslint:disable */
// prettier-ignore
// ReSharper disable RedundantEmptyConstructor
// ReSharper disable InconsistentNaming
/* 
 *
 * This file is automatically generated, all manual changes to this file will be lost.
 *
 * To regenerate this file use the 'entitygen' tool. 
 *
 */
// ------ Enum members ------
export enum ErrorCategory
{
    unknown = 0,
    sessionExpired = 1,
    clientValidationError = 2,
    serverValidationError = 3,
    dataMappingError = 4,
    endpointIsDown = 5,
    configError = 6,
    endpointUnknownError = 7,
    critical = 8
}
export enum FullmaktType
{
    ordinaerFullmakt = 3,
    utenSamtykkekompenanseOver12 = 7
}
export enum GetFullmaktType
{
    gitt = 1,
    mottatt = 2,
    gittRepresentasjonsforhold = 3
}
export enum GetPersonForFullmaktStatus
{
    funnet = 1,
    ikkeFunnet = 2,
    forMangeSok = 3,
    kanIkkeGiFullmaktTilDegSelv = 4,
    kanIkkeGiFullmaktForRepresentertBruker = 5,
    brukerUnder16KanIkkeGiFullmakt = 6,
    brukerUnder18KanIkkeHaFullmakt = 7,
    ugyldigPersonnummer = 8,
    harIkkeHelsenorgeSamtykke = 9
}
export enum RepresentasjonsforholdForeldreansvarSubType
{
    dagligOmsorg = 1
}
export enum RepresentasjonsforholdFullmaktSubType
{
    ordinaer = 1,
    tildelt = 2
}
export enum RepresentasjonsforholdType
{
    innloggetBruker = 0,
    fullmakt = 1,
    foreldreansvar = 2
}
export enum SamtykkeNivaa
{
    ingen = 0,
    basis = 1,
    basisPluss = 2,
    fullt = 3
}
export enum SprakKoder
{
    norsk = 1,
    nynorsk = 2,
    samisk = 3,
    engelsk = 4,
    polsk = 5,
    litauisk = 6,
    rumensk = 7,
    russisk = 8
}
export enum TjenesteOmradeType
{
    avtalerOgBehandlere = 1,
    okonomiOgRettigheter = 2,
    behandlingOgOppfolging = 3,
    innsynIHelsetjenestensJournalerOgRegistre = 4,
    brukerinnstillingerOgPersonvern = 5,
    alle = 6,
    vestlandspasienten = 7,
    innsynIOpplysningerRegistrertOmMeg = 8,
    innsynIPasientjournal = 9,
    digitalHelsetjeneste = 10
}
export enum TjenesteOmradeTypeUt
{
    innsynIOpplysningerRegistrertOmMeg = 8,
    innsynIPasientjournal = 9,
    digitalHelsetjeneste = 10
}
// ReSharper restore RedundantEmptyConstructor
// ReSharper restore InconsistentNaming
/* tslint:enable */