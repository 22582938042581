/** Cookie-navn for om man er logget inn */
export const LOGGED_IN_COOKIE_NAME = 'MH_LoggedIn';
/** Cookie-navn for sesjons-ID */
export const SESSION_ID_COOKIE_NAME = 'MH_SessionId';
/** Cookie-navn for måten man har logget inn på */
export const IDENTITY_PROVIDER_COOKIE_NAME = 'MH_Idp';
/**
 * Sjekker om cookie finnes og om den har verdi
 * @param cookieName navn på cookie
 * @param value cookie verdi
 */
export const hasCookie = (cookieName, value) => {
    if (typeof document === 'undefined') {
        return false;
    }
    const cookie = document.cookie;
    if (cookie === undefined) {
        return false;
    }
    if (value === undefined) {
        return cookie.split('; ').find(e => e.split('=')[0] === cookieName) !== undefined;
    }
    const cookieValue = '; '.concat(document.cookie);
    const parts = cookieValue.split('; '.concat(cookieName).concat('='));
    if (parts.length === 2) {
        const lastPart = parts.pop();
        return lastPart ? lastPart.split(';').shift() === value.toString() : false;
    }
    return false;
};
/**
 * Henter cookienavn inkludert eventuelt suffix for å støtte ulike domener.
 * @param cookieName navn på cookie
 */
export const getSuffix = (cookieName) => {
    let suffix = '';
    if (window.HN?.Rest?.Environment) {
        suffix = '_' + window.HN.Rest.Environment;
    }
    return cookieName + suffix;
};
/**
 * Sjekker om cookie finnes og om den har verdi
 * @param cookieName navn på cookie
 */
export const hasCookieWithSuffix = (cookieName) => {
    return hasCookie(getSuffix(cookieName));
};
/**
 * Sjekker om bruker er logget inn basert på cookie
 */
export const hasLoggedInCookie = () => {
    return hasCookieWithSuffix(LOGGED_IN_COOKIE_NAME) || hasCookieWithSuffix(SESSION_ID_COOKIE_NAME);
};
/**
 * Setter en cookie med van + verdi
 * @param cookieName navn på cookie
 * @param value cookie verdi
 */
export const setCookie = (name, value = '') => {
    document.cookie = `${name}=${value};path=/;`;
};
/**
 * Sletter en cookie basert på navnet
 * @param cookieName navn på cookie
 */
export const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
/**
 * Henter verdien til en cookie basert på navnet
 * @param cookieName navn på cookie
 */
export const getCookieValue = (name) => {
    const cookie = hasCookie(name);
    const cookieWithSuffix = hasCookieWithSuffix(name);
    if (!cookie && !cookieWithSuffix) {
        return undefined;
    }
    const cookieName = cookieWithSuffix ? getSuffix(name) : name;
    return document.cookie
        .split('; ')
        .find(e => e.split('=')[0] === cookieName)
        ?.split('=')[1];
};
/**
 * Returnerer domenenavnet uten subdomene, f.eks. "helsenorge.no" dersom domenet er "www.helsenorge.no" eller "tjenester.helsenorge.no"
 * @returns domenenavn for bruk i cookie
 */
export const getHostnameWithoutSubdomain = () => window.location.hostname.substr(window.location.hostname.indexOf('.') + 1);
export var IdentityProvider;
(function (IdentityProvider) {
    IdentityProvider["HnApp"] = "hnapp";
    IdentityProvider["HnAppUngdom"] = "idportenlevel3";
    IdentityProvider["IdPorten"] = "idporten";
    IdentityProvider["IdPortenLevel4"] = "idportenlevel4";
})(IdentityProvider || (IdentityProvider = {}));
/**
 * Henter måten bruker har logget inn på
 * @returns IdentityProvider
 */
export const getIdentityProvider = () => getCookieValue(IDENTITY_PROVIDER_COOKIE_NAME);
