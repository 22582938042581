import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Size } from '../types/entities';
import typography from '@helsenorge/designsystem-react/scss/typography.module.scss';
import cmsstyles from './styles.module.scss';
const Image = ({ imageUrl, caption, copyright, size = Size.Large, alternativeText, }) => {
    const figureClass = 'row no-gutters';
    let imageClass = 'col-12 align-self-start';
    let captionClass = 'col-12';
    if (size === Size.Small) {
        imageClass = 'col-12 col-md-10 offset-md-1 col-lg-5 offset-lg-2 align-self-start';
        captionClass = classNames(typography['image-caption'], 'col-12 col-md-10 offset-md-1 col-lg-3 offset-lg-0 pl-lg-4');
    }
    else if (size === Size.Large) {
        captionClass = classNames(typography['image-caption'], 'col-12 col-lg-8');
    }
    else if (size === Size.Medium) {
        imageClass = 'col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2';
        captionClass = classNames(typography['image-caption'], 'col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 ');
    }
    return (_jsxs("figure", { className: figureClass, children: [_jsx("img", { src: imageUrl, alt: alternativeText, className: imageClass }), _jsxs("figcaption", { className: captionClass, children: [_jsx("p", { className: classNames(cmsstyles.image, size === Size.Small && cmsstyles['image--small']), children: caption }), _jsx("p", { className: classNames(typography['image-credit'], cmsstyles['image__copyright']), children: copyright })] })] }));
};
export default Image;
