import { useEffect } from 'react';
import { HeaderFooterEvents, CmsBlocksEvents, CommonEvents, WebComponents, StepEvents } from './constants';
import { setVisPersonvelger } from '../hn-user';
const dispatchCustomEvent = (selector, eventType, detail) => {
    const element = document.querySelector(selector);
    if (element?.dispatchEvent) {
        element.dispatchEvent(new CustomEvent(eventType, {
            cancelable: true,
            detail,
        }));
    }
};
export const HNeventSetSimplifiedHeader = (isSimplified = true) => dispatchCustomEvent('hn-webcomp-header', HeaderFooterEvents.setsimplifiedheader, { simplifiedHeader: isSimplified });
export const HNeventSetFirstTimeLogin = (isFirstTime = true) => dispatchCustomEvent('hn-webcomp-header', HeaderFooterEvents.setfirsttimelogin, { firstTimeLogin: isFirstTime });
export const HNeventSetAnonymousHeader = (isAnonymous = true) => dispatchCustomEvent('hn-webcomp-header', HeaderFooterEvents.setanonymousheader, { anonymousHeader: isAnonymous });
export const HNeventSetHiddenHeader = (isHidden = true) => dispatchCustomEvent('hn-webcomp-header', HeaderFooterEvents.sethiddenheader, { hiddenHeader: isHidden });
export const HNeventSetSimplifiedFooter = (text, isSimplified = true) => dispatchCustomEvent('hn-webcomp-footer', HeaderFooterEvents.setsimplifiedfooter, {
    simplifiedFooter: isSimplified,
    simplifiedFooterText: text,
});
export const HNeventSetHiddenFooter = (isHidden = true) => dispatchCustomEvent('hn-webcomp-footer', HeaderFooterEvents.sethiddenfooter, {
    hiddenFooter: isHidden,
});
export const HNeventSetNoMarginFooter = (noMargin = true) => dispatchCustomEvent('hn-webcomp-footer', HeaderFooterEvents.setnomarginfooter, {
    noMarginFooter: noMargin,
});
export const HNeventRefreshVarslinger = () => dispatchCustomEvent('hn-webcomp-header', HeaderFooterEvents.refreshvarslinger, {});
export const HNeventSetDriftsmeldingPath = (path) => dispatchCustomEvent('hn-webcomp-driftspanel', HeaderFooterEvents.setdriftsmeldingpath, { path });
/**
 * @deprecated Bruk HNeventRefreshVarslinger
 */
export const HNeventRefreshVarslingerOgHendelsesmeny = () => dispatchCustomEvent('hn-webcomp-header', HeaderFooterEvents.refreshvarslingeroghendelsesmeny, {});
export const HNeventSetOnShowSignoutbox = (fn) => dispatchCustomEvent('hn-webcomp-header', HeaderFooterEvents.setonshowsignoutbox, { onShowSignOutBox: fn });
export const HNeventSetUserLoading = (userLoading) => dispatchCustomEvent('hn-webcomp-header', HeaderFooterEvents.setuserloading, { userLoading });
export const HNeventSoonAutoSignOut = () => dispatchCustomEvent('hn-webcomp-header', HeaderFooterEvents.soonautosignout, {});
export const HNeventShowAppInvitation = (onAppOpen, onWebLogin, onClose) => dispatchCustomEvent('hn-webcomp-header', HeaderFooterEvents.showappinvitation, { onAppOpen, onWebLogin, onClose });
export const HNeventSetRedirectTo = (redirectTo) => dispatchCustomEvent('hn-webcomp-personvelger', HeaderFooterEvents.setredirectto, { redirectTo });
export const HNeventSetRedirectToUrlAfterLogin = (redirectToUrlAfterLogin) => dispatchCustomEvent('hn-webcomp-personvelger', HeaderFooterEvents.setredirecttourlafterlogin, {
    redirectToUrlAfterLogin,
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HNeventSetSurveys = (surveys) => dispatchCustomEvent('hn-webcomp-footer', HeaderFooterEvents.setsurveys, { surveys });
export const HNeventSetFooterData = (data) => dispatchCustomEvent('hn-webcomp-footer', HeaderFooterEvents.setfooterdata, data);
export const HNeventSetVisPersonvelger = (visPersonvelger) => {
    setVisPersonvelger(visPersonvelger);
    window.dispatchEvent(new CustomEvent(HeaderFooterEvents.setvispersonvelger, {
        detail: { visPersonvelger },
    }));
};
export const HNeventSetHiddenPromopanel = (isHidden) => dispatchCustomEvent(WebComponents.Promopanel, CmsBlocksEvents.setHiddenPromopanel, { hiddenPromopanel: isHidden });
export const HNeventIsMicroFrontendMounted = (webcomponentName) => {
    const webcomponent = document.querySelector(webcomponentName);
    if (webcomponent?.dispatchEvent) {
        webcomponent.dispatchEvent(new CustomEvent(CommonEvents.isMounted));
    }
};
export const HNaddEventListener = (event, element, handler, passive = false) => {
    useEffect(() => {
        if (element)
            element.addEventListener(event, handler, passive);
        return function cleanup() {
            if (element)
                element.removeEventListener(event, handler);
        };
    });
};
export const HNeventNavigateStep = (stepId, detail) => dispatchCustomEvent(`#${stepId}`, StepEvents.Navigate, detail);
export const HNeventSendFromWebComponent = (stepId, detail) => dispatchCustomEvent(`#${stepId}`, StepEvents.SendFromWebComponent, detail);
