export var LanguageLocales;
(function (LanguageLocales) {
    LanguageLocales["NORWEGIAN"] = "nb-NO";
    LanguageLocales["NORWEGIAN_NYNORSK"] = "nn-NO";
    LanguageLocales["ENGLISH"] = "en-GB";
    LanguageLocales["LITHUANIAN"] = "lt-LT";
    LanguageLocales["POLISH"] = "pl-PL";
    LanguageLocales["RUSSIAN"] = "ru-RU";
    LanguageLocales["ROMANIAN"] = "ro-RO";
    LanguageLocales["SAMI_NORTHERN"] = "se-NO";
})(LanguageLocales || (LanguageLocales = {}));
export default LanguageLocales;
