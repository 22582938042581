export var PromoBlockType;
(function (PromoBlockType) {
    PromoBlockType["promoboard"] = "promoboard";
    PromoBlockType["diveboard"] = "diveboard";
})(PromoBlockType || (PromoBlockType = {}));
export var PromoBlockColors;
(function (PromoBlockColors) {
    PromoBlockColors["blueberry50"] = "blueberry50";
    PromoBlockColors["blueberry500"] = "blueberry500";
    PromoBlockColors["cherry50"] = "cherry50";
    PromoBlockColors["cherry500"] = "cherry500";
    PromoBlockColors["neutral50"] = "neutral50";
    PromoBlockColors["neutral700"] = "neutral700";
})(PromoBlockColors || (PromoBlockColors = {}));
export var ImageType;
(function (ImageType) {
    ImageType["illustration"] = "illustration";
    ImageType["photo"] = "photo";
})(ImageType || (ImageType = {}));
// search
export var WindowActionEnum;
(function (WindowActionEnum) {
    WindowActionEnum[WindowActionEnum["None"] = 0] = "None";
    WindowActionEnum[WindowActionEnum["RefreshPage"] = 1] = "RefreshPage";
})(WindowActionEnum || (WindowActionEnum = {}));
export var Size;
(function (Size) {
    Size["Small"] = "Small";
    Size["Medium"] = "Medium";
    Size["Large"] = "Large";
})(Size || (Size = {}));
export var VideoType;
(function (VideoType) {
    VideoType[VideoType["notSupported"] = 0] = "notSupported";
    VideoType[VideoType["youtube"] = 1] = "youtube";
    VideoType[VideoType["vimeo"] = 2] = "vimeo";
    VideoType[VideoType["vimeoShowcase"] = 3] = "vimeoShowcase";
})(VideoType || (VideoType = {}));
// Setter antall cards per bootstrap rad (eks: four = 12/4)
export var CardsPerRow;
(function (CardsPerRow) {
    CardsPerRow[CardsPerRow["four"] = 3] = "four";
    CardsPerRow[CardsPerRow["three"] = 4] = "three";
    CardsPerRow[CardsPerRow["two"] = 6] = "two";
    CardsPerRow[CardsPerRow["one"] = 12] = "one";
})(CardsPerRow || (CardsPerRow = {}));
// Setter fargetema for videokort
export var VideoTheme;
(function (VideoTheme) {
    VideoTheme["neutral"] = "neutral";
    VideoTheme["cherry"] = "cherry";
    VideoTheme["blueberry"] = "blueberry";
})(VideoTheme || (VideoTheme = {}));
