import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import classNames from 'classnames';
import Button from '@helsenorge/designsystem-react/components/Button';
import Checkbox from '@helsenorge/designsystem-react/components/Checkbox';
import Title from '@helsenorge/designsystem-react/components/Title';
import designsystemtypography from '@helsenorge/designsystem-react/scss/typography.module.scss';
import cmsstyles from './styles.module.scss';
const YouTubeTermsOfService = ({ title, imageUrl, resources, handleAccept, }) => {
    const checkboxRef = useRef(null);
    return (_jsxs("div", { className: cmsstyles['tos-overlay'], style: {
            backgroundImage: imageUrl ? `linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(${imageUrl})` : undefined,
        }, children: [title && (_jsx(Title, { className: cmsstyles['tos-overlay__title'], htmlMarkup: "h2", appearance: "title3", children: title })), _jsxs("div", { className: cmsstyles['tos'], children: [_jsxs("p", { className: classNames(cmsstyles['tos__text'], designsystemtypography['anchorlink-wrapper']), children: [resources.youtubeTosText, ' ', _jsx("a", { href: resources.youtubeTosLinkHref ?? 'https://www.youtube.com/t/terms', target: "_blank", rel: "noopener noreferrer", children: resources.youtubeTosLinkText }), ' ', resources.youtubeTosTextSuffix] }), _jsx("div", { className: cmsstyles['tos__remember'], children: _jsx(Checkbox, { label: resources.tosRememberLabel, ref: checkboxRef }) }), _jsx(Button, { onClick: () => handleAccept(checkboxRef.current?.checked ?? false), children: resources.tosButtonText })] })] }));
};
export default YouTubeTermsOfService;
