import React from 'react';

import { createHashRouter, createBrowserRouter, RouterProvider, useLoaderData, RouteObject } from 'react-router-dom';

import Loader from '@helsenorge/designsystem-react/components/Loader';

import { erTjenester } from '@helsenorge/framework-utils/hn-proxy-service';
import { setAllowNavigation } from '@helsenorge/framework-utils/hn-user';

import usePersonvelger from '../../hooks/usePersonvelger';
import { personvelgerLoader, PersonvelgerLoaderData } from '../../router/loader';
import ErrorElement from '../error';
import PersonvelgerListe from '../personliste';

export const Personvelger: React.FC = () => {
  const {
    users,
    errors: { profileError },
    loading,
    handleSelectUser,
  } = usePersonvelger();
  const { headerFooter, personvelgerStatusMessages, representasjonsforholdError } = useLoaderData() as PersonvelgerLoaderData;

  const handleRedirect = (newPersonHasBeenSelected: boolean): void => {
    if (newPersonHasBeenSelected) {
      setAllowNavigation(true);
      window.location.replace('/');
    }
  };

  return (
    headerFooter?.header.personvelger && (
      <PersonvelgerListe
        messages={personvelgerStatusMessages}
        resources={headerFooter?.header.personvelger}
        users={users}
        onSelectUser={user => handleSelectUser(user, () => handleRedirect(!user.selected))}
        highlightUser={true}
        errors={{
          profileError,
          representasjonsforholdError,
        }}
      >
        {loading && <Loader center />}
      </PersonvelgerListe>
    )
  );
};

const routes: RouteObject[] = [
  {
    path: '*',
    loader: personvelgerLoader,
    element: <Personvelger />,
    errorElement: <ErrorElement />,
  },
];

const PersonvelgerComponent: React.FC = () => {
  const createRouter = erTjenester() ? createBrowserRouter : createHashRouter;

  const router = createRouter(routes);

  return <RouterProvider router={router} />;
};

export default PersonvelgerComponent;
