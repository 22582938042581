import { jsx as _jsx } from "react/jsx-runtime";
import EkspanderendeBlock from './components/ekspanderendeblock';
import ImageBlock from './components/imageblock';
import Link from './components/link';
import LinkListBlock from './components/linklistblock';
import LoginBlock from './components/loginblock';
import TextBlock from './components/textblock';
import RichTextTitle from './components/title';
import VideoBlock from './components/videoblock';
import VideoMatriseBlock from './components/videomatriseblock';
export const globalComponents = {
    types: {
        EkspanderendeBlock,
        ImageBlock,
        LinkListBlock,
        TextBlock,
        VideoBlock,
        VideoMatriseBlock,
        LoginBlock,
    },
    block: {
        h1: ({ children }) => _jsx(RichTextTitle, { htmlMarkup: "h1", children: children }),
        h2: ({ children }) => _jsx(RichTextTitle, { htmlMarkup: "h2", children: children }),
        h3: ({ children }) => _jsx(RichTextTitle, { htmlMarkup: "h3", children: children }),
        h4: ({ children }) => _jsx(RichTextTitle, { htmlMarkup: "h4", children: children }),
        h5: ({ children }) => _jsx(RichTextTitle, { htmlMarkup: "h5", children: children }),
    },
    marks: {
        link: Link,
        superscript: ({ children }) => _jsx("sup", { children: children }),
        subscript: ({ children }) => _jsx("sub", { children: children }),
    },
};
