import { createHeaders, getTjenesterUrl } from './hn-proxy-service';
import { checkStatus, parseParams } from './hn-service';
const baseCrud = (method, path, params, data) => fetch(getTjenesterUrl() + path + parseParams(params), {
    method,
    credentials: 'include',
    headers: createHeaders(),
    body: data ? JSON.stringify(data) : undefined,
}).then(response => checkStatus(response));
/**
 * Hent (GET) JSON-data fra APIer på tjenester.helsenorge.no som ikke er bak proxy
 * @param path Relativ path til API-endepunkt, må starte med /
 * @param params Objekt med parametere som vil bli lagt til slutten av URL til ?
 * @returns Responsdata fra APIet som JSON
 */
export const get = (path, params) => baseCrud('GET', path, params);
/**
 * Send (PUT) JSON-data til APIer på tjenester.helsenorge.no som ikke er bak proxy
 * @param path Relativ path til API-endepunkt, må starte med /
 * @param params Objekt med parametere som vil bli lagt til slutten av URL til ?
 * @param data Data som skal sendes
 * @returns Responsdata fra APIet som JSON
 */
export const put = (path, params, data) => baseCrud('PUT', path, params, data);
