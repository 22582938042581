import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import bindClassNames from 'classnames/bind';
import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ArrowRight from '@helsenorge/designsystem-react/components/Icons/ArrowRight';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import Title from '@helsenorge/designsystem-react/components/Title';
import { useHover } from '@helsenorge/designsystem-react';
import { getIconColor } from '../shared/getIconColor';
import styles from './styles.module.scss';
const classNames = bindClassNames.bind(styles);
const Panel = ({ url, icon, emphasize, title, linkTitle, intro, colorName = 'neutral', children, linkTarget = '_self', className, large, }) => {
    const { hoverRef, isHovered } = useHover();
    const iconComponent = icon &&
        (large ? (_jsxs(_Fragment, { children: [_jsx(LazyIcon, { iconName: icon, size: IconSize.Medium, isHovered: isHovered, className: "d-lg-none" }), _jsx(LazyIcon, { iconName: icon, size: IconSize.Large, isHovered: isHovered, className: "d-none d-lg-inline" })] })) : (_jsx(LazyIcon, { iconName: icon, size: IconSize.Medium, isHovered: isHovered })));
    const iconOrEmphasisComponent = (icon || emphasize) && (_jsx("span", { className: classNames('col-6', 'col-md-2', {
            'pl-lg-7': icon && large && !emphasize,
            'panel__emphasize pr-md-3': !icon && emphasize,
        }), children: icon ? iconComponent : emphasize }));
    const spacingClassNames = classNames(className, 'px-5', 'pt-5', 'pt-md-6', 'pb-7', 'pb-md-8', 'my-7', 'my-lg-8', `panel--${colorName}`, {
        'pt-lg-5': large && iconOrEmphasisComponent,
    });
    const titleComponent = (_jsxs(Title, { htmlMarkup: "h2", appearance: "title3", className: classNames('my-0', 'row', 'no-gutters', 'align-items-center', {
            'align-items-md-baseline': !icon && emphasize,
        }), children: [iconOrEmphasisComponent, url && (_jsx("span", { className: classNames(styles.panel__arrow, 'align-self-md-baseline', {
                    'col-6 col-md-2 order-md-3': iconOrEmphasisComponent,
                    'col-2 order-3': !iconOrEmphasisComponent,
                }), children: _jsx(Icon, { size: IconSize.XSmall, svgIcon: ArrowRight, color: getIconColor('blueberry'), isHovered: isHovered }) })), _jsx("span", { className: classNames({
                    'col-md-8 mt-5 mt-md-0': iconOrEmphasisComponent,
                    'col-10': !iconOrEmphasisComponent,
                }), children: title })] }));
    const renderSection = (_jsxs("div", { className: classNames({
            [spacingClassNames]: !url,
        }), children: [title && titleComponent, (intro || children) && (_jsx("div", { className: "row no-gutters", children: _jsxs("div", { className: classNames('mt-3', 'mt-md-5', 'mb-0', {
                        'col-md-8 offset-md-2': icon || emphasize,
                        'col-md-10': !icon && !emphasize,
                    }), children: [intro, children] }) }))] }));
    if (!url) {
        return renderSection;
    }
    const linkClassNames = classNames(styles.panel__link, `panel__link--${colorName}`);
    return (_jsx("a", { href: url, className: classNames('d-block', linkClassNames, spacingClassNames), title: linkTitle ? linkTitle : title, ref: hoverRef, target: linkTarget, rel: linkTarget && linkTarget === '_blank' ? 'noopener noreferrer' : undefined, children: renderSection }));
};
export default Panel;
