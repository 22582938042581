import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from 'react';
import { PortableText as PortableTextReact } from '@portabletext/react';
import { globalComponents } from './portable-text-utils';
export const PortableTextContext = createContext(undefined);
export const PortableText = ({ components, value, className, backgroundColor, ...rest }) => {
    if (!value) {
        return null;
    }
    return (_jsx(PortableTextContext.Provider, { value: { backgroundColor: backgroundColor }, children: _jsx("div", { className: className, children: _jsx(PortableTextReact, { components: { ...globalComponents, ...components }, value: value, ...rest }) }) }));
};
export default PortableText;
