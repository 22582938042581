import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import { getAppUrl } from '@helsenorge/framework-utils/url';
import { HNeventShowAppInvitation } from '@helsenorge/framework-utils/web-component/events';
const LoginButton = ({ htmlMarkup, href, icon, rightSideIcon, children, ...rest }) => {
    const [appUrl, setAppUrl] = React.useState(undefined);
    const buttonRef = React.useRef(null);
    React.useEffect(() => {
        setAppUrl(getAppUrl());
    }, []);
    const triggerOriginalBehaviour = (e) => {
        rest.onClick && rest.onClick(e);
        if (href)
            window.location.href = href;
    };
    const useAppUrl = (appUrl) => {
        const url = new URL(href ? href : '');
        window.location.href = `${appUrl}?tjeneste=${url.pathname}${url.search}`;
    };
    const mobileOnClick = (e) => {
        if (appUrl) {
            e?.preventDefault();
            HNeventShowAppInvitation(() => {
                useAppUrl(appUrl);
            }, () => {
                triggerOriginalBehaviour();
            }, () => {
                buttonRef.current?.focus();
            });
        }
    };
    return (_jsxs(Button, { ...rest, href: appUrl ? undefined : href, onClick: appUrl ? mobileOnClick : rest.onClick, htmlMarkup: appUrl ? 'button' : htmlMarkup, ref: buttonRef, children: [!!icon && _jsx(LazyIcon, { iconName: icon }), children, !!rightSideIcon && _jsx(Icon, { svgIcon: rightSideIcon })] }));
};
LoginButton.defaultProps = {
    size: 'medium',
    concept: 'normal',
    href: '#',
    htmlMarkup: 'button',
    fluid: false,
};
export default LoginButton;
