/// <reference types="@helsenorge/framework-utils/types/hn"/>

import { getTjenesterUrl } from '@helsenorge/framework-utils/hn-proxy-service';
import { debug } from '@helsenorge/framework-utils/logger';

export const createCrossDomainIFrame = (): void => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = getTjenesterUrl() + '/Core/CrossDomain/SessionKeepAlive';
  iframe.id = 'cross_domain_iframe';
  document.body.appendChild(iframe);
};

export default class TimerLogic {
  private countdown: number;
  private warnUserWhenRemaining: number;
  private tokenLifetime: number;

  private signOutCountdownTimer = -1;
  private automaticSignOutTimer = -1;
  private automaticSignOutWarningTimer = -1;
  private soonAutoSignOutEventTimer = -1;

  constructor() {
    this.countdown = window.HN?.Rest?.TokenCountdown ?? 0;
    this.warnUserWhenRemaining = window.HN?.Rest?.TokenWarnUserWhenRemaining ?? 0;
    this.tokenLifetime = window.HN?.Rest?.TokenLifetime ?? 0;
  }

  getMinutesUntilSessionExpire(expireDate: Date): number {
    const now: Date = new Date();
    now.setTime(now.getTime() + now.getTimezoneOffset() * 60000);
    const expiresInMinutes: number = (expireDate.getTime() - now.getTime() - this.warnUserWhenRemaining) / 60000;
    return expiresInMinutes;
  }

  initializeCheckRefreshTimeout(timeoutFunction: () => void): void {
    const timeout: number = this.tokenLifetime - this.warnUserWhenRemaining;
    const timeoutTime = Date.now() + timeout;

    this.checkTimeout(timeoutFunction, timeoutTime);
  }

  initSoonAutoSignOutEvent(soonAutoSignoutEvent: () => void): void {
    if (this.soonAutoSignOutEventTimer > -1) {
      window.clearTimeout(this.soonAutoSignOutEventTimer);
    }

    this.soonAutoSignOutEventTimer = window.setTimeout(() => {
      if (this.signOutCountdownTimer > -1) {
        debug('Trigger soonautosignoutevent ', new Date());
        soonAutoSignoutEvent();
      }
    }, this.getSoonAutoSignOutEventTime());
  }

  getSoonAutoSignOutEventTime(): number {
    const eventTimeBeforeSignout = 2 * 60 * 1000;
    return this.countdown - eventTimeBeforeSignout;
  }

  checkTimeout(timeoutFunction: () => void, timeoutTime: number): void {
    if (Date.now() < timeoutTime) {
      window.setTimeout(() => {
        this.checkTimeout(timeoutFunction, timeoutTime);
      }, 1000);
    } else {
      timeoutFunction();
    }
  }

  initializeAutomaticSignOutTimeout(timeoutFunction: () => void): void {
    if (this.automaticSignOutTimer > -1) {
      window.clearTimeout(this.automaticSignOutTimer);
    }
    this.automaticSignOutTimer = window.setTimeout(timeoutFunction, this.countdown);
  }

  initializeAutomaticSignOutWarningTimeout(expireDate: Date, timeoutFunction: () => void): void {
    if (this.automaticSignOutWarningTimer > -1) {
      window.clearTimeout(this.automaticSignOutWarningTimer);
    }
    const signOutWarningTime: number = this.getMinutesUntilSessionExpire(expireDate) * 60000;
    this.automaticSignOutWarningTimer = window.setTimeout(timeoutFunction, signOutWarningTime);
  }

  intializeSignOutCountdownTimer(timeoutFunction: () => void): void {
    if (this.signOutCountdownTimer === -1) {
      this.signOutCountdownTimer = window.setInterval(timeoutFunction, 1000);
    }
  }

  stopSignOutCountdownTimer(): void {
    if (this.signOutCountdownTimer > -1) {
      window.clearInterval(this.signOutCountdownTimer);
      this.signOutCountdownTimer = -1;
    }
  }

  stopTimeouts(): void {
    if (this.automaticSignOutTimer > -1) {
      window.clearTimeout(this.automaticSignOutTimer);
      this.automaticSignOutTimer = -1;
    }

    if (this.automaticSignOutWarningTimer > -1) {
      window.clearTimeout(this.automaticSignOutWarningTimer);
      this.automaticSignOutWarningTimer = -1;
    }

    this.stopSignOutCountdownTimer();
  }

  getCountdownMinutes(): number {
    return Math.floor(this.countdown / 60000);
  }

  getCountdownSeconds(): number {
    const diff: number = this.countdown - this.getCountdownMinutes() * 60000;
    return Math.floor(diff / 1000);
  }
}
