import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import ArrowRight from '@helsenorge/designsystem-react/components/Icons/ArrowRight';
import { hasLoggedInCookie } from '@helsenorge/framework-utils/cookie';
import LoginButton from './LoginButton';
import Panel from '../panel';
const LoginBlock = ({ title, intro, authenticatedTitle, authenticatedIntro, hideIfAuthenticated = false, url, iconName, buttonText, colorName, showOnlyButton, }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
        const isAuthorized = hasLoggedInCookie();
        setIsAuthenticated(isAuthorized);
    }, []);
    if (isAuthenticated && hideIfAuthenticated) {
        return null;
    }
    return (_jsx(_Fragment, { children: showOnlyButton && !isAuthenticated ? (_jsx(LoginButton, { icon: iconName, concept: "normal", fluid: true, htmlMarkup: "a", rightSideIcon: ArrowRight, size: "large", href: url, children: buttonText })) : (_jsx(Panel, { icon: iconName, title: isAuthenticated && authenticatedTitle ? authenticatedTitle : title, intro: isAuthenticated && authenticatedIntro ? authenticatedIntro : intro, url: isAuthenticated ? url : '', colorName: colorName, children: !isAuthenticated && (_jsx("div", { className: "mt-5", children: _jsx(LoginButton, { htmlMarkup: "a", href: url, children: buttonText }) })) })) }));
};
export default LoginBlock;
