import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { useContext } from 'react';
import { VideoTheme } from '../../../types/entities';
import { PortableTextContext } from '../..';
import VideoMatriseComponent from '../../../videomatrise';
const VideoMatriseBlock = ({ value }) => {
    const backgroundColor = useContext(PortableTextContext)?.backgroundColor;
    return (_jsx(VideoMatriseComponent, { title: value.title, videoCards: value.videoCards, cardsPerRow: value.cardsPerRow, theme: backgroundColor && isVideoThemeColor(backgroundColor) ? backgroundColor : value.theme, resources: value.resources }));
};
function isVideoThemeColor(value) {
    return Object.values(VideoTheme).includes(value);
}
export default VideoMatriseBlock;
