/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { decycle } from './cycle';
import { getServerLogLevel, getTjenesterUrl, HelsenorgeHttpError, HelsenorgeProxyError } from './hn-proxy-service';
import { createBaseHeaders } from './hn-service';
export var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["Trace"] = 0] = "Trace";
    LogLevel[LogLevel["Debug"] = 1] = "Debug";
    LogLevel[LogLevel["Info"] = 2] = "Info";
    LogLevel[LogLevel["Warn"] = 3] = "Warn";
    LogLevel[LogLevel["Error"] = 4] = "Error";
    LogLevel[LogLevel["Fatal"] = 5] = "Fatal";
    LogLevel[LogLevel["Off"] = 6] = "Off";
})(LogLevel || (LogLevel = {}));
const unwrapError = (potentialError) => {
    if (potentialError instanceof HelsenorgeProxyError) {
        return `${potentialError.message} Status code: ${potentialError.statusCode} CorrelationId: ${potentialError.correlationId} Stack: ${potentialError.stack}`;
    }
    else if (potentialError instanceof HelsenorgeHttpError) {
        return `${potentialError.message} Status code: ${potentialError.statusCode} Stack: ${potentialError.stack}`;
    }
    else if (potentialError instanceof Error) {
        return `${potentialError.message} Stack: ${potentialError.stack}`;
    }
    else if (typeof potentialError === 'string' || potentialError instanceof String) {
        return potentialError;
    }
    else if (potentialError.length > 0) {
        const innerError = potentialError[0];
        return unwrapError(innerError);
    }
    else {
        return JSON.stringify(decycle(potentialError));
    }
};
const generateEntry = (level, message, ...optionalParams) => {
    let fullMessage = message;
    fullMessage = optionalParams.reduce((previous, current) => {
        return previous + ' ' + unwrapError(current);
    }, fullMessage ?? '');
    return {
        Level: level,
        Message: fullMessage,
        Url: window.location.href,
    };
};
const postLogEntry = (logEntry) => {
    fetch(getTjenesterUrl() + '/api/v1/Frontend/Log', {
        method: 'post',
        headers: createBaseHeaders(),
        credentials: 'include',
        body: JSON.stringify(logEntry),
    }).catch();
};
/**
 * Logger error til serveren
 */
export const logToServer = (level, message, ...optionalParams) => {
    const logLevel = getServerLogLevel();
    if (logLevel !== null && level >= logLevel) {
        exportFunctions.postLogEntry(generateEntry(level, message, optionalParams));
    }
};
/**
 * Lager en event listener på errors og logger error til serveren
 */
export const captureErrors = () => {
    window.addEventListener('error', function (e) {
        let message = e.message;
        const error = e.error;
        if (error) {
            message = error;
            const stack = error.stack;
            if (stack) {
                message = stack;
            }
        }
        else {
            message = message + '. Access to error information is restricted on this domain!';
            if (e.filename) {
                message = message + ' Filename:' + e.filename;
            }
        }
        exportFunctions.logToServer(LogLevel.Error, message);
    });
};
/**
 * Lager en console.debug og logger til serveren
 * @param message - string som sendes i console.debug og logges til serveren
 * @param optionalParams params som sendes til console.debug og logges til serveren
 */
export const debug = (message, ...optionalParams) => {
    if (allowConsoleOutput()) {
        console.debug(message, optionalParams);
    }
    exportFunctions.logToServer(LogLevel.Debug, message, optionalParams);
};
/**
 * Lager en console.error og logger til serveren
 * @param message - string som sendes i console.error og logges til serveren
 * @param optionalParams params som sendes til console.error og logges til serveren
 */
export const error = (message, ...optionalParams) => {
    if (allowConsoleOutput()) {
        console.error(message, optionalParams);
    }
    exportFunctions.logToServer(LogLevel.Error, message, optionalParams);
};
/**
 * Lager en console.info og logger til serveren
 * @param message - string som sendes i console.info og logges til serveren
 * @param optionalParams params som sendes til console.info og logges til serveren
 */
export const info = (message, ...optionalParams) => {
    if (allowConsoleOutput()) {
        console.info(message, optionalParams);
    }
    exportFunctions.logToServer(LogLevel.Info, message, optionalParams);
};
/**
 * Lager en console.trace og logger til serveren
 * @param message - string som sendes i console.trace og til serveren
 * @param optionalParams params som sendes til console.trace og til serveren
 */
export const trace = (message, ...optionalParams) => {
    if (allowConsoleOutput()) {
        console.trace(message, optionalParams);
    }
    exportFunctions.logToServer(LogLevel.Trace, message, optionalParams);
};
/**
 * Lager en console.warn og logger til serveren
 * @param message - string som sendes i console.warn og til serveren
 * @param optionalParams params som sendes til console.warn og til serveren
 */
export const warn = (message, ...optionalParams) => {
    if (allowConsoleOutput()) {
        console.warn(message, optionalParams);
    }
    exportFunctions.logToServer(LogLevel.Warn, message, optionalParams);
};
export const allowConsoleOutput = () => !['production', 'test'].includes(process.env.NODE_ENV ?? '');
/**
 * Lager en console.assert
 * @param test - test som sendes til console assert
 * @param message - message som sendes til console assert
 * @param optionalParams params som sendes til console assert
 */
export const assert = (test, message, ...optionalParams) => {
    if (allowConsoleOutput()) {
        console.assert(test, message, optionalParams);
    }
};
/**
 * Lager en console.clear
 */
export const clear = () => {
    if (allowConsoleOutput()) {
        console.clear();
    }
};
/**
 * Lager en console.count
 * @param countTitle - string som sendes i console.count
 */
export const count = (countTitle) => {
    if (allowConsoleOutput()) {
        console.count(countTitle);
    }
};
/**
 * Lager en console.dir
 * @param value - string som sendes i console.dir og logges til serveren
 * @param optionalParams params som sendes til console.dir og logges til serveren
 */
export const dir = (value, ...optionalParams) => {
    if (allowConsoleOutput()) {
        console.dir(value, optionalParams);
    }
};
/**
 * Lager en console.dirxml
 * @param value - string som sendes i console.dirxml og logges til serveren
 */
export const dirxml = (value) => {
    if (allowConsoleOutput()) {
        console.dirxml(value);
    }
};
/**
 * Lager en console.group
 * @param groupTitle - string som sendes i console.group
 */
export const group = (groupTitle) => {
    if (allowConsoleOutput()) {
        console.group(groupTitle);
    }
};
/**
 * Lager en console.groupCollapsed
 * @param groupTitle - string som sendes i console.groupCollapsed
 */
export const groupCollapsed = (groupTitle) => {
    if (allowConsoleOutput()) {
        console.groupCollapsed(groupTitle);
    }
};
/**
 * Lager en console.groupEnd
 */
export const groupEnd = () => {
    if (allowConsoleOutput()) {
        console.groupEnd();
    }
};
/**
 * Lager en console.log
 * @param message - string som sendes i console.log
 * @param optionalParams params som sendes til console.log
 */
export const log = (message, ...optionalParams) => {
    if (allowConsoleOutput()) {
        if (optionalParams && optionalParams.length > 0) {
            console.log(message, optionalParams);
        }
        else {
            console.log(message);
        }
    }
};
/**
 * Lager en console.profile
 * @param reportName - string som sendes i console.groupCollapsed
 */
export const profile = (reportName) => {
    if (allowConsoleOutput()) {
        console.profile(reportName);
    }
};
/**
 * Lager en console.profileEnd
 */
export const profileEnd = () => {
    if (allowConsoleOutput()) {
        console.profileEnd();
    }
};
/**
 * Lager en console.table
 * @param data - array av ukjent data som sendes videre til console.table
 */
export const table = (...data) => {
    if (allowConsoleOutput()) {
        console.table(data);
    }
};
/**
 * Lager en console.time
 * @param timerName - string som sendes videre til console.time
 */
export const time = (timerName) => {
    if (allowConsoleOutput()) {
        console.time(timerName);
    }
};
/**
 * Lager en console.timeEnd
 * @param timerName - string som sendes videre til console.timeEnd
 */
export const timeEnd = (timerName) => {
    if (allowConsoleOutput()) {
        console.timeEnd(timerName);
    }
};
// This wrapping is necessary for being able to Mock function in vi-tests
const exportFunctions = {
    postLogEntry,
    logToServer,
    debug,
    error,
    info,
    trace,
    warn,
    assert,
    clear,
    count,
    dir,
    dirxml,
    group,
    groupCollapsed,
    groupEnd,
    log,
    profile,
    profileEnd,
    table,
    time,
    timeEnd,
};
export default exportFunctions;
