import React, { useState, useEffect, useContext } from 'react';

import { SessionTimeoutAction } from '../../types/entities';

import { erTjenester } from '@helsenorge/framework-utils/hn-proxy-service';
import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { SubscribeContext } from '@helsenorge/framework-utils/web-component/context';
import { WebcompProps } from '@helsenorge/framework-utils/web-component/register';

import { createCrossDomainIFrame } from './session-utils';
import WarningLightbox, { ShowSignOutBoxData } from './warning-lightbox';

interface Props {
  isauthorized?: string;
  lightboxinactivitytitle?: string;
  lightboxinactivitydescription?: string;
  lightboxinactivitytimer?: string;
  lightboxinactivitydoyouwanttostay?: string;
  lightboxinactivitybuttonyes?: string;
  lightboxinactivitybuttonsignout?: string;
  inactivitysignedouttitle?: string;
  inactivitysignedoutbuttongotoroot?: string;
  inactivitysignedoutbuttonsigninagain?: string;
}

const Session: React.FC<Props & WebcompProps> = props => {
  const isAuthorized = props.isauthorized === 'true';
  const subscribe = useContext(SubscribeContext);

  const [onShowSignOutBox, setOnShowSignOutBox] = useState<(data: ShowSignOutBoxData) => SessionTimeoutAction>();

  useEffect(() => {
    subscribe(HeaderFooterEvents.setonshowsignoutbox, (event: CustomEvent) => {
      setOnShowSignOutBox(() => event.detail.onShowSignOutBox);
    });
  }, []);

  useEffect(() => {
    if (!erTjenester() && isAuthorized) {
      createCrossDomainIFrame();
    }
  }, [isAuthorized]);

  return (
    <WarningLightbox
      resources={{
        lightboxInactivityTitle: props.lightboxinactivitytitle,
        lightboxInactivityDescription: props.lightboxinactivitydescription,
        lightboxInactivityTimer: props.lightboxinactivitytimer,
        lightboxInactivityDoYouWantToStay: props.lightboxinactivitydoyouwanttostay,
        lightboxInactivityButtonYes: props.lightboxinactivitybuttonyes,
        lightboxInactivityButtonSignOut: props.lightboxinactivitybuttonsignout,
        inactivitySignedOutTitle: props.inactivitysignedouttitle,
        inactivitySignedOutButtonGoToRoot: props.inactivitysignedoutbuttongotoroot,
        inactivitySignedOutButtonSignInAgain: props.inactivitysignedoutbuttonsigninagain,
      }}
      onShowSignOutBox={onShowSignOutBox}
    />
  );
};

export default Session;
