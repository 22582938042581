/**
 * Returnerer Authorized fra HN.rest objektet
 */
export const isAuthorized = () => {
    if (typeof document === 'undefined') {
        return false;
    }
    return !!window.HN?.Rest?.Authorized;
};
/**
 * Returnerer HashIsAuthorized fra HN.rest objektet
 */
export const hashIsAuthorized = () => {
    return window.HN?.Rest?.HashIsAuthorized ?? false;
};
