import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { useContext } from 'react';
import { PortableTextContext } from '../..';
import LoginBlockComponent from '../../../loginblock';
const getColorName = (contextColor, blockColor) => {
    // Når farge på blokken er "Ingen", bruk farge hentet fra parent-komponent
    if (contextColor && blockColor === 'none') {
        return contextColor;
    }
    // Ellers, bruk farge fra blokken
    if (blockColor !== 'none') {
        return blockColor;
    }
};
const LoginBlock = ({ value }) => {
    const backgroundColor = useContext(PortableTextContext)?.backgroundColor;
    const colorName = getColorName(backgroundColor, value.colorName);
    return (_jsx(LoginBlockComponent, { title: value.title, intro: value.intro, authenticatedTitle: value.authenticatedTitle, authenticatedIntro: value.authenticatedIntro, hideIfAuthenticated: value.hideIfAuthenticated, url: value.url, iconName: value.iconName, buttonText: value.buttonText, colorName: colorName, showOnlyButton: value.showOnlyButton }));
};
export default LoginBlock;
