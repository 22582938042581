import React, { useContext, useEffect } from 'react';

import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { SubscribeContext } from '@helsenorge/framework-utils/web-component/context';
import { RedirectToDetail, RedirectToUrlAfterLoginDetail } from '@helsenorge/framework-utils/web-component/events';

import { useRedirectContext } from '../redirect-provider';

interface EventSubscriberProps {
  children: React.ReactNode;
}

const EventSubscriber: React.FC<EventSubscriberProps> = ({ children }) => {
  const subscribe = useContext(SubscribeContext);
  const { setRedirectToUrlAfterLogin, setRedirectTo } = useRedirectContext();

  useEffect(() => {
    subscribe(HeaderFooterEvents.setredirectto, (event: CustomEvent<RedirectToDetail>) => {
      setRedirectTo(event.detail.redirectTo);
    });

    /** @deprecated Slett når alle har tatt i bruk v31 */
    subscribe(HeaderFooterEvents.setfromlocation, (event: CustomEvent<{ fromLocation: Location }>) => {
      setRedirectTo(event.detail.fromLocation.pathname);
    });

    subscribe(HeaderFooterEvents.setredirecttourlafterlogin, (event: CustomEvent<RedirectToUrlAfterLoginDetail>) => {
      setRedirectToUrlAfterLogin(event.detail.redirectToUrlAfterLogin);
    });
  }, []);

  return <>{children}</>;
};

export default EventSubscriber;
