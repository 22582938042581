/**
 * Session
 */
export interface AccessTokenExpirationOperationResponse {
  Expire: string;
}

export enum SessionTimeoutAction {
  Default = 0,
  RequestExtension = 1,
  RequestLogout = 2,
}
