export const fallbackHeaderFooter = {
    header: {
        globalMenu: {
            headerButtonMeny: 'Meny',
            startPageUrl: '',
            linkList: [],
            menuItems: [],
        },
        searchMenu: {
            headerButtonSok: 'Søk',
            searchPageUrl: '',
            headerMenuSearchTitle: '',
            headerMenuSearchLabel: '',
            headerMenuSearchSubmit: '',
        },
        fasteTekster: {
            headerButtonLogin: 'Logg inn',
            headerButtonLoginUrl: '/auth/signin',
            headerButtonLogout: 'Logg ut',
            headerButtonLogoutUrl: '/auth/signout',
            hoppTilInnholdet: 'Hopp til innhold',
            headerButtonVarsler: 'Varsler',
            varslerAriaLabelNone: 'Ingen uleste varsler',
            varslerAriaLabelSome: 'Uleste varsler',
            varslerAriaLabelOne: 'Ett ulest varsel',
            varslerAriaLabelMultiple: '{count} uleste varsler',
            headerAriaLabel: '',
            openMenu: '',
            closeMenu: '',
            lightboxInactivityTitle: '',
            lightboxInactivityDescription: '',
            lightboxInactivityTimer: '',
            lightboxInactivityDoYouWantToStay: '',
            lightboxInactivityButtonYes: '',
            lightboxInactivityButtonSignOut: '',
            inactivitySignedOutTitle: '',
            inactivitySignedOutButtonGoToRoot: '',
            inactivitySignedOutButtonSignInAgain: '',
            modalErrorTitle: '',
            modalErrorDescription: '',
            modalErrorButtonText: '',
        },
        profileMenu: {
            headerMenuProfileVarslingerDuHar: 'Du har',
            headerMenuProfileVarslingerIngen: 'ingen',
            headerMenuProfileVarslingerMeldinger: 'nye meldinger',
            headerMenuProfileVarslingerHendelser: 'nye hendelser',
            headerMenuProfileRepresentasjonerTitle: 'Hvem vil du bruke Helsenorge på vegne av?',
            headerMenuProfileLinksTitle: 'Profil og innstillinger',
            headerMenuProfileSistInnlogget: 'Du var sist innlogget ',
            errorVarslinger: 'Det har skjedd en feil. Varslingene kan ikke vises.',
            linkList: [],
        },
        personvelger: {
            personvelgerHeader: '',
            headerMenuProfileRepresentasjonerDegSelv: '',
            headerMenuProfileRepresentasjonerDuHarFullmakt: '',
            headerMenuProfileRepresentasjonerDuHarForeldreansvar: '',
            personvelgerIngress: '',
            representasjonerBarn16Plus: [],
            representasjonerBarnAnnenAdresse: [],
            representasjonerManglendeForeldreansvar: [],
            errorInnloggetBruker: '',
            errorHentBruker: '',
            errorValgtPerson: '',
            errorFullmakter: '',
            errorRepresentasjoner: '',
        },
        appInvitation: {
            title: '',
            description: '',
            primaryButtonText: '',
            secondaryButtonText: '',
            closeButtonLabel: '',
        },
    },
    footer: {
        footerMenu: {
            managingEditor: '',
            supportHotline: {
                schemaNumber: '',
                displayNumber: '',
                icon: '',
                displayText: '',
                phoneNumberPrefix: '',
                phoneNumberSuffix: '',
            },
            footerColumns: [],
            languageLinkList: [],
        },
        fasteTekster: {
            footerAriaLabel: '',
        },
    },
};
