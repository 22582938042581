import { jsx as _jsx } from "react/jsx-runtime";
import Title from '@helsenorge/designsystem-react/components/Title';
import cmsstyles from './styles.module.scss';
const RichTextTitle = ({ htmlMarkup, children }) => {
    const htmlMarkupAppearanceMap = {
        h1: 'title1',
        h2: 'title2',
        h3: 'title3',
        h4: 'title4',
        h5: 'title5',
        span: 'title5',
    };
    return (_jsx(Title, { className: cmsstyles.richtext, htmlMarkup: htmlMarkup, appearance: htmlMarkupAppearanceMap[htmlMarkup], children: children }));
};
export default RichTextTitle;
