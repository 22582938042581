import React from 'react';

import classNames from 'classnames';

import { RepresentasjonsforholdType } from '../../types/entities/representasjon.v1enums';

import Avatar from '@helsenorge/designsystem-react/components/Avatar';
import Badge from '@helsenorge/designsystem-react/components/Badge';

import styles from './styles.module.scss';

export interface Person {
  /** Brukers navn */
  fulltNavn: string;
  /** Brukers profilGuid */
  profilGuid: string;
  /** Om bruker er nåværende representasjon */
  selected: boolean;
  /** Type representasjonsforhold */
  representasjonforholdType: RepresentasjonsforholdType;
  /** Tekst som beskriver representasjonstypen */
  representasjonsType: string;
  /** Antall uleste varsler */
  antallUleste: number;
}

interface PersonListeItemProps {
  user: Person;
  highlight?: boolean;
  onSelectUser: () => void;
}

const PersonListeItem: React.FC<PersonListeItemProps> = props => {
  const highlight = props.highlight && props.user.selected;

  const buttonClasses: string = classNames(styles.personliste__person, {
    [styles['personliste__person--highlight']]: highlight,
  });

  const handleSelectUser: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();

    props.onSelectUser();
  };

  return (
    <li className={styles.personliste__item}>
      <button type="button" className={buttonClasses} onClick={handleSelectUser}>
        <Avatar selected={highlight}>{props.user.fulltNavn}</Avatar>

        <span className={styles.personliste__text}>
          <strong className={styles.personliste__name}>{props.user.fulltNavn}</strong>
          {props.user.representasjonsType && <span className={styles.personliste__separator}>{` - `}</span>}
          {props.user.representasjonsType && (
            <span className={styles.personliste__representasjonstype}>{props.user.representasjonsType}</span>
          )}
        </span>
        {!!props.user.antallUleste && <Badge color="cherry">{props.user.antallUleste}</Badge>}
      </button>
    </li>
  );
};

export default PersonListeItem;
