import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { CardsPerRow, VideoTheme } from '../types/entities';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import Play from '@helsenorge/designsystem-react/components/Icons/Play';
import designsystemtypography from '@helsenorge/designsystem-react/scss/typography.module.scss';
import { theme as designTheme, useHover, useUuid } from '@helsenorge/designsystem-react';
import { AnalyticsId } from '../shared/analytics';
import cmsstyles from './styles.module.scss';
const VideoKort = ({ title, description, imageUrl, onClick, wrapperClassName, selected, theme = VideoTheme.cherry, hasFullWidth, cardsPerRow, testId, playVideoLabel = 'Spill av video', }) => {
    const uuid = useUuid();
    const buttonId = `videocard-button-${uuid}`;
    const titleId = `videocard-title-${uuid}`;
    const descriptionId = `videocard-description-${uuid}`;
    const { hoverRef, isHovered } = useHover();
    const wrapperClasses = classNames(wrapperClassName, cmsstyles['videocard-wrapper']);
    const videoCardClasses = classNames(cmsstyles.videocard, cmsstyles[`videocard--${theme}`], selected && cmsstyles['videocard--selected']);
    const imageClasses = classNames(cmsstyles.videocard__image, cardsPerRow == CardsPerRow.two && !hasFullWidth ? cmsstyles['videocard__image--medium-height'] : '', cardsPerRow == CardsPerRow.two && hasFullWidth ? cmsstyles['videocard__image--tall-height'] : '', cardsPerRow == CardsPerRow.four && hasFullWidth ? cmsstyles['videocard__image--tall-height-tablet'] : '', cardsPerRow == CardsPerRow.one ? cmsstyles['videocard__image--max-height'] : '');
    const buttonAriaLabelledBy = title ? `${buttonId} ${titleId}` : buttonId;
    const buttonAriaDescribedBy = description ? descriptionId : undefined;
    if (imageUrl?.length === 0) {
        return null;
    }
    const playLabelClasses = classNames(designsystemtypography.title3, cmsstyles.playbutton__label, {
        [cmsstyles['playbutton__label--active']]: isHovered || selected,
    });
    const titleClasses = classNames(designsystemtypography.title3, cmsstyles.videocard__title);
    return (_jsx("div", { className: wrapperClasses, ref: hoverRef, children: _jsxs("div", { className: videoCardClasses, "data-analyticsid": AnalyticsId.Videokort, "data-testid": testId, children: [_jsx("div", { className: cmsstyles['videocard__image-wrapper'], children: _jsx("img", { className: imageClasses, alt: "", src: imageUrl }) }), _jsxs("div", { className: cmsstyles.videocard__content, children: [_jsxs("button", { className: cmsstyles.playbutton, "aria-labelledby": buttonAriaLabelledBy, "aria-describedby": buttonAriaDescribedBy, "aria-expanded": selected, onClick: onClick, children: [_jsx(Icon, { size: 36, color: isHovered ? designTheme.palette.blueberry800 : designTheme.palette.blueberry700, svgIcon: Play, isHovered: isHovered }), _jsx("span", { id: buttonId, className: playLabelClasses, children: playVideoLabel })] }), title && (_jsx("h3", { id: titleId, className: titleClasses, children: title })), description && (_jsx("p", { className: cmsstyles.videocard__description, id: buttonAriaDescribedBy, children: description }))] })] }) }));
};
export default VideoKort;
