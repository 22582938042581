import { getHostnameWithoutSubdomain } from './cookie';
export const __cookieOptions__ = { secure: 'secure' };
/**
 * Returnerer domene og path for bruk i cookie som skal gjelde på tvers av åpne sider og tjenester
 * @returns domain og path for bruk i cookie
 */
export const getCookieDomainAndPath = () => `domain=${getHostnameWithoutSubdomain()}; path=/;`;
/**
 * Lagrer cookie på tvers av åpne sider og tjenester
 * @param name navn på cookie
 * @param value verdi på cookie
 * @param maxAgeInDays levetid på cookie i dager
 */
export const setSharedCookie = (name, value, maxAgeInDays = 365) => {
    const maxAgeInSeconds = maxAgeInDays * 24 * 60 * 60;
    document.cookie = `${name}=${value}; ${getCookieDomainAndPath()} max-age=${maxAgeInSeconds}; ${__cookieOptions__.secure}`;
};
/**
 * Sletter cookie på tvers av åpne sider og tjenester
 * @param name navn på cookie
 */
export const deleteSharedCookie = (name) => {
    document.cookie = `${name}=; ${getCookieDomainAndPath()} expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
