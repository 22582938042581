import registerWebComp from '@helsenorge/framework-utils/web-component/register';

import PersonvelgerCms from './components/personvelger-cms';
import PersonvelgerComponent from './components/personvelger-component';
import PersonvelgerTjenester from './components/personvelger-tjenester';
import Session from './components/session';

async function enableMocking(): Promise<ServiceWorkerRegistration | void> {
  if (import.meta.env.MODE !== 'mocks') {
    return;
  }

  const { worker } = await import('./__mocks__/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

enableMocking().then(() => {
  registerWebComp(PersonvelgerTjenester, 'hn-webcomp-personvelger', { events: true }, 'hn-webcomp-personvelger-template');
  registerWebComp(PersonvelgerComponent, 'hn-webcomp-personvelger-component', { events: false }, 'hn-webcomp-personvelger-template');
  registerWebComp(PersonvelgerCms, 'hn-webcomp-personvelger-cms', { events: false }, 'hn-webcomp-personvelger-template');
  registerWebComp(Session, 'hn-webcomp-session', { events: false }, 'hn-webcomp-personvelger-template');
});
