import { getUrlHostname } from '@helsenorge/core-utils/url';
import { isAndroid, isAppleMobileDevice } from '@helsenorge/core-utils/user-agents-utils';
import { getAssetsUrl } from './hn-page';
import { getTjenesterUrl } from './hn-proxy-service';
/**
 * Sjekk om URL peker til et helsenorge-domene
 * @param url En gyldig URL, f.eks. https://www.helsenorge.no/sykdom/
 * @returns returnerer true dersom domene er tjenester.helsenorge.no eller www.helsenorge.no
 */
export const isHelsenorgeUrl = (url) => {
    const hostname = getUrlHostname(url);
    return !!hostname && ['tjenester.helsenorge.no', 'www.helsenorge.no'].includes(hostname);
};
export const getAppUrl = () => {
    if (isAndroid()) {
        return getAssetsUrl() + '/core/app-login/android.html';
    }
    else if (isAppleMobileDevice()) {
        return getAssetsUrl() + '/core/app-login/apple.html';
    }
    else {
        return undefined;
    }
};
export const getSigninAppUrl = () => {
    const appUrl = getAppUrl();
    if (appUrl) {
        const urlSearch = new URL(window.location.href).search;
        return `${getAppUrl()}${urlSearch}`;
    }
    return undefined;
};
export const getSigninTjenesteUrl = () => {
    const currentUrl = new URL(window.location.href);
    const tjenesteParam = currentUrl.searchParams.get('tjeneste') || '';
    const sourceUrl = `${getTjenesterUrl()}/${tjenesteParam}`;
    const signinUrl = new URL(`${getTjenesterUrl()}/auth/signin`);
    signinUrl.searchParams.set('Source', sourceUrl);
    return signinUrl.toString();
};
