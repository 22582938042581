import React, { createContext, useContext, useState } from 'react';

interface RedirectContext {
  redirectToUrlAfterLogin: boolean;
  redirectTo?: string;
  setRedirectToUrlAfterLogin: (value: boolean) => void;
  setRedirectTo: (url: string) => void;
}

const RedirectContext = createContext<RedirectContext | undefined>(undefined);

export const useRedirectContext = (): RedirectContext => {
  const context = useContext(RedirectContext);

  if (!context) {
    throw new Error('useRedirectContext must be used within a RedirectProvider');
  }

  return context;
};

interface RedirectProviderProps {
  children: React.ReactNode;
}

const RedirectProvider: React.FC<RedirectProviderProps> = ({ children }) => {
  const [redirectToUrlAfterLogin, setRedirectToUrlAfterLogin] = useState(false);
  const [redirectTo, setRedirectTo] = useState<string>();

  return (
    <RedirectContext.Provider
      value={{
        redirectToUrlAfterLogin,
        redirectTo,
        setRedirectToUrlAfterLogin,
        setRedirectTo,
      }}
    >
      {children}
    </RedirectContext.Provider>
  );
};

export default RedirectProvider;
